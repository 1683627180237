.teachers-slider {
  &__item {
    background-color: $color-white;
    color: $color-black;
    border-radius: 10px;
    padding: 10px;

    @include respond-to(macbook) {
      padding: 20px;
    }
  }

  &__header {
    @include respond-to(macbook) {
      min-height: 240px;
      padding: 0 0 0 270px;
      margin: 0 0 28px;
    }
  }

  &__photo,
  &__no-photo {
    border-radius: 10px;
    border: none;
    object-fit: cover;

    @include respond-to(macbook) {
      top: 20px;
      left: 20px;
      transform: none;
    }
  }

  &__content {
    @include respond-to(macbook) {
      margin: 0;

      &:before {
        display: none;
      }
    }
  }
}
