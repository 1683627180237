.slider-description {
  position: relative;

  &__item {
    @include respond-to(macbook) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      min-height: 240px;
      padding: 0 0 0 290px;
    }
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px;
    padding: 0 0 0 120px;
    margin: 0 0 15px;

    @include respond-to(tablet) {
      min-height: 228px;
      padding: 0 0 0 258px;
      margin: 0 0 28px;
    }

    @include respond-to(macbook) {
      position: initial;
      min-height: auto;
      padding: 0;
      margin: 0;
    }
  }

  &__photo,
  &__no-photo {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100px;
    height: 100px;
    display: block;
    border: 4px solid $color-white;
    border-radius: 50%;
    transform: translateY(-50%);
    object-fit: cover;

    @include respond-to(tablet) {
      width: 228px;
      height: 228px;
    }

    @include respond-to(macbook) {
      width: 240px;
      height: 240px;
    }
  }

  &__no-photo {
    border: none;
  }

  &__name {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 700;
    margin: 0 0 6px;

    @include respond-to(tablet) {
      font-size: 20px;
      margin: 0 0 20px;
    }
  }

  &__post {
    line-height: 1.3;
    font-style: italic;

    @include respond-to(tablet) {
      font-size: 16px;
    }
  }

  &__content {
    position: relative;
    line-height: 1.86;

    @include respond-to(tablet) {
      line-height: 1.65;
      font-size: 16px;
    }

    @include respond-to(macbook) {
      margin: 36px 0 0;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: -19px;
        width: 100px;
        height: 4px;
        background: $color-main;
      }
    }

    p {
      &:first-child {
        margin-top: 0;
      }
    }

    p,
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
