@import "./variables";
@import "./mixins";

@import "~antd/dist/antd.css";
@import "./antd/global";
@import "./antd/message";
@import "./antd/spin";
@import "ol/ol.css";
@import "@vshp/ui-components/dist/index.scss";

@import "./theme";

@import "./ui/form.scss";

@import "./block/header";
@import "./block/footer";
@import "./block/wysiwyg";
@import "./block/tables";
@import "./block/intro";
@import "./block/map";
@import "./block/chat";
@import "./block/scrollbar";
@import "./block/errors-list";
@import "./block/accreditation.scss";
@import "./block/list-cards.scss";
@import "./block/videos.scss";
@import "./block/greeting.scss";
@import "./block/slider.scss";
@import "./block/slider-description.scss";
@import "./block/programs.scss";
@import "./block/photos.scss";
@import "./block/partners.scss";
@import "./block/life.scss";
@import "./block/certificates.scss";
@import "./block/contacts.scss";
@import "./block/teachers.scss";

@import "./pages/page";
@import "./pages/main";
@import "./pages/not-found";
@import "./pages/posts.scss";

@import "./accessibility";
